import styled from 'styled-components'
import Link from '../components/ui/Link'

// Project Single
export const Hero = styled.div`
	position: relative;
	background: ${({ gradientdark, gradientlight }) => {
		return 'linear-gradient(90deg, ' + gradientlight + ', ' + gradientdark + ')'
	}};

	.row {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	@media (max-width: 1450px) {
		.gatsby-image-wrapper {
			margin-right: -50px;
		}

		img {
			margin-right: -25px;
		}
	}
`

export const Header = styled.h3`
	&.title {
		font-size: 2.625rem;
		line-height: 3.75rem;
		padding-bottom: 2rem;
	}
	@media (max-width: 1024px) {
		&.title {
			font-size: 2rem;
			line-height: 2.75rem;
			letter-spacing: 0px;
		}
	}

	@media (max-width: 525px) {
		&.title {
			font-size: 1.875rem;
			max-width: 100%;
		}
	}
`
export const HeaderWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1200px) {
		div[class*='ImgWrap'] {
			display: none;
		}
	}
`
export const SingleContent = styled.div`
	.swiper-container {
		max-width: 320px;
		padding-bottom: 32px;
		margin-bottom: 64px;

		.swiper-slide {
			max-width: calc(100% - 44px);
			max-height: calc(100% - 44px);
			margin: 22px;
			box-shadow: 4px 4px 22px 0px rgba(0, 0, 0, 0.2);
			border-radius: 7px;
		}

		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
			background-color: transparent;
			border: 1px solid ${({ theme }) => theme.swiperBullets};
			opacity: 1;

			&:focus {
				outline: none;
			}
		}

		.swiper-pagination-bullet-active {
			background-color: ${({ theme }) => theme.swiperBullets};
		}
	}

	@media (max-width: 1024px) {
		div.row {
			padding: 12.8em 5em;
		}
	}

	@media (max-width: 525px) {
		div.row {
			padding: 6.4em 2em;
		}
	}
`

export const Content = styled.div`
	max-width: 740px;
	z-index: 1;
	padding: 182px 0 64px;

	@media (max-width: 1024px) {
		padding: 128px 0 64px;
	}

	@media (max-width: 525px) {
		padding: 92px 0 48px;
	}
`

export const DataWrap = styled.div`
	display: flex;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`

export const DataItem = styled.div`
	:first-of-type {
		padding-right: 4rem;

		@media (max-width: 768px) {
			margin-bottom: 16px;
		}
	}
`

export const DataTitle = styled.span`
	font-size: 0.875rem;
	line-height: 1.3125rem;
	color: white;

	@media (max-width: 1024px) {
		font-size: 0.625rem;
		line-height: 1rem;
		letter-spacing: 0px;
	}
`

export const DataValue = styled.p`
	font-size: 1.25rem;
	font-family: 'Poppins Bold';
	margin: 0;
	color: white;

	@media (max-width: 1024px) {
		font-size: 0.875rem;
		line-height: 1.3125rem;
		letter-spacing: 0px;
	}
`

export const ShortDescription = styled.div`
	padding: 1rem 0 3rem;

	p {
		font-size: 1.25rem;
		line-height: 2.125rem;
		letter-spacing: 0.48px;
	}

	@media (max-width: 1024px) {
		p {
			font-size: 0.875rem;
			line-height: 1.625rem;
			letter-spacing: 0.34px;
		}
	}

	@media (max-width: 525px) {
		padding: 1rem 0 24px;
	}
`

export const SingleFlexibleContent = styled.div`
	.titleText,
	.singleImage,
	.singleText {
		padding-bottom: 256px;
	}

	.slider {
		display: none;
	}

	.titleText,
	.singleText {
		p {
			margin-top: 0;
		}
	}

	.twoImages {
		display: flex;
		justify-content: space-between;
		padding-bottom: 256px;

		.gatsby-image-wrapper {
			width: 45%;
		}

		&.bigAndSmall {
			align-items: flex-end;

			.gatsby-image-wrapper {
				width: 16.12%;
				height: 77.41%;

				&:first-of-type {
					width: 79.33%;
					height: 100%;
				}
			}
		}

		@media (max-width: 525px) {
			.gatsby-image-wrapper {
				&:first-of-type {
					margin-bottom: 9em;
				}
			}
		}
	}

	.dropShadow {
		box-shadow: 4px 4px 22px 0px rgba(0, 0, 0, 0.2);
		border-radius: 20px;
	}

	& > div:last-of-type {
		padding-bottom: 0;

		& > div {
			margin-bottom: 0;
		}
	}

	.slider {
		margin-bottom: 128px;
		margin-left: -20px;
		margin-right: -20px;

		.gatsby-image-wrapper {
			width: 74%;
			margin: 0 auto;
		}

		.slick-list,
		.slick-track {
			transform: translate3d(0, 0, 0);
			touch-action: pan-y;
		}
	}

	@media (max-width: 1024px) {
		.titleText,
		.singleImage,
		.singleText,
		.twoImages {
			padding-bottom: 128px;
		}

		.dropShadow {
			border-radius: 7px;
		}
	}

	@media (max-width: 525px) {
		.slider {
			display: block;
		}

		.singleImage {
			&.h-slider {
				display: none;
			}
		}

		.titleText,
		.singleImage,
		.singleText,
		.twoImages {
			padding-bottom: 64px;
		}

		.titleText {
			.title {
				max-width: 100%;
			}
		}

		.twoImages {
			flex-wrap: wrap;

			.gatsby-image-wrapper {
				width: 100%;
			}

			&.bigAndSmall {
				justify-content: center;

				.gatsby-image-wrapper {
					width: 100%;
					max-width: 247px;
					height: 100%;

					&:first-of-type {
						width: 100%;
						max-width: 100%;
						margin-bottom: 6.4em;
					}
				}
			}
		}
	}
`

export const ImgWrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	.gatsby-image-wrapper {
		width: 100%;
		max-width: 460px;
		margin-top: 80px;
	}
`

export const ImageRightContent = styled.div`
	margin-bottom: 0;

	.title {
		max-width: 100%;
	}

	.content {
		padding-right: 6.4em;
		padding-left: 0;
	}

	.gatsby-image-wrapper {
		max-width: 420px;
		margin: 0 auto;
	}

	@media (max-width: 1024px) {
		.gatsby-image-wrapper {
			max-width: 320px;
		}
	}
`

export const ImageLeftContent = styled.div`
	margin-top: 25.6em;

	.title {
		max-width: 100%;
	}

	.s-image {
		display: flex;
	}

	.content {
		padding-left: 6.4em;
		padding-right: 0;
	}

	.gatsby-image-wrapper {
		max-width: 420px;
		margin: 0 auto;
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		margin-top: 12.8em;

		.gatsby-image-wrapper {
			max-width: 320px;
		}

		.s-image,
		.content {
			max-width: 100%;
			width: 100%;
			order: 1;
			padding-left: 0;
			padding-right: 0;
		}

		.content {
			padding-bottom: 128px;
		}

		.s-image {
			order: 2;
		}
	}

	@media (max-width: 525px) {
		margin-top: 6.4em;

		.content {
			padding-bottom: 64px !important;
		}
	}
`

// Project List

export const Flex = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	justify-content: flex-end;
`

export const Column = styled.div`
	flex-basis: 100%;
	max-width: 100%;
	margin-bottom: 19.7em;
	text-transform: capitalize;

	picture img,
	img {
		transition: all 0.3s ease 0s !important;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	&:hover {
		picture img,
		img {
			transform: scale(1.02);
		}
	}

	@media (max-width: 1024px) {
		margin-bottom: 15.6em;
	}

	@media (max-width: 800px) {
		margin-bottom: 12.8em;
	}

	@media (max-width: 525px) {
		margin-bottom: 6.4em;

		&:hover {
			picture img {
				transform: none;
			}
		}
	}
`

export const ImageWrap = styled(Link)`
	position: relative;
	z-index: 1;
	display: block;
	overflow: hidden;
	background: ${({ gradientdark, gradientlight }) => {
		return (
			'transparent linear-gradient(297deg, ' +
			gradientdark +
			' 0%, ' +
			gradientlight +
			' 100%) 0% 0% no-repeat padding-box;'
		)
	}};

	img {
		width: 100%;
	}

	.mm {
		display: none;
	}

	@media (max-width: 525px) {
		box-sizing: border-box;
		margin-left: -20em;
		margin-right: -20em;
		padding-top: 60px;
		display: flex;

		.nm {
			display: none;
		}

		.mm {
			display: block;
		}

		.gatsby-image-wrapper,
		img {
			max-height: 346px;
			width: 20.2%;
			margin: 0 auto;

			& > div {
				padding-bottom: 197.183% !important;
			}
		}

		&.website {
			padding-top: 88px;
			padding-bottom: 87px;

			.gatsby-image-wrapper,
			img {
				max-height: 408px;
				width: 100vw;
				margin: 0 auto;

				& > div {
					padding-bottom: 84.7917% !important;
				}
			}
		}
	}
`

export const Info = styled.div`
	position: relative;
	margin-bottom: 2rem;

	@media (max-width: 800px) {
		margin-bottom: 1rem;
	}
`

export const ClientWrap = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	img {
		margin-right: 1rem;
	}

	@media (max-width: 800px) {
		margin-bottom: 8px;

		img {
			width: 32px;
		}
	}
`

export const ArchiveClient = styled.div`
	mix-blend-mode: difference;

	a {
		font-size: 2.25rem;
		line-height: 3.125rem;
		letter-spacing: 0.86px;
		font-weight: bold;
		text-transform: capitalize;
		color: white;
	}

	@media (max-width: 1024px) {
		a {
			font-size: 1.4375rem;
			line-height: normal;
		}
	}
`

export const Description = styled.p`
	font-size: 1.125rem;
	line-height: 1.6875rem;
	text-transform: none;
`
export const PCta = styled.div`
	position: absolute;
	top: 40%;
	transform: translateY(-50%);
	right: 0;
	background-color: #2a2a2a;
	transition: opacity 0.25s;
	border-radius: 3px;
	display: block;

	a {
		padding: 12px 18px;
		font-size: 18px;
		line-height: 18px;
		letter-spacing: 0.43px;
		color: #fff;
		text-transform: none;
		font-family: 'Poppins Regular';
		display: block;
	}

	&:hover {
		opacity: 0.7;
	}

	@media (max-width: 1024px) {
		a {
			font-size: 1rem;
		}
	}

	@media (max-width: 800px) {
		display: none;
	}
`

// Blog
export const List = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export const FImage = styled.img`
	width: 170%;
`

export const ListWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(33.33% - 21.3px);
	margin-right: 32px;
	margin-bottom: 64px;

	&.blog-wrap,
	&.post-recommended {
		.img-wrap {
			position: relative;
			overflow: hidden;
			padding-top: 58%;

			${FImage} {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.gatsby-image-wrapper {
				position: absolute !important;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&.blog-wrap:first-of-type {
		flex-direction: row;
		width: 100%;
		margin-right: 0;

		.img-wrap {
			max-width: 928px;
			width: 100%;
			padding-top: 35.5%;

			${FImage} {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 155%;
			}
		}

		p {
			flex: 1;
		}

		@media (max-width: 1240px) {
			.img-wrap {
				${FImage} {
					width: 220%;
				}
			}
		}

		@media (max-width: 768px) {
			.img-wrap {
				${FImage} {
					width: 155%;
				}
			}
		}
	}

	&:last-of-type,
	&:nth-of-type(4n) {
		margin-right: 0;
	}

	@media (max-width: 1450px) {
		&.blog-wrap:first-of-type {
			.img-wrap {
				max-width: 50vw;
			}
		}
	}

	@media (max-width: 1270px) {
		&.blog-wrap:first-of-type {
			.img-wrap .gatsby-image-wrapper {
				height: 100%;
			}
		}
	}

	@media (pointer: coarse), (max-width: 1024px) {
		width: calc(50% - 11px);
		margin-right: 22px;
		margin-bottom: 42px;

		&:last-of-type,
		&:nth-of-type(3n) {
			margin-right: 0;
		}

		&:nth-of-type(5n) {
			margin-right: 0;
		}

		&:nth-of-type(4n) {
			margin-right: 22px;
		}

		&:last-of-type {
			margin-left: 22px;
		}

		&.post-recommended {
			width: 100%;
			margin-right: 0;
		}
	}

	@media (max-width: 999px) {
		&.blog-wrap:first-of-type {
			p {
				display: none;
			}
		}
	}

	@media (max-width: 768px) {
		&.blog-wrap:first-of-type {
			.img-wrap {
				max-width: 52vw;
			}
		}
	}

	@media (max-width: 700px) {
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 36px;

		&.blog-wrap:first-of-type {
			flex-direction: column;

			.img-wrap {
				max-width: 100%;
			}

			p {
				display: block;
			}
		}
	}

	@media (max-width: 525px) {
		/* .img-wrap .gatsby-image-wrapper {
			min-height: 165px;
			height: 165px;
		} */

		&.blog-wrap:first-of-type .img-wrap {
			padding-top: 58% !important;
		}
	}

	.text-wrapp {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		box-shadow: 0px 1px 10px #0000000f;

		/* .title-link {
		flex: 1;
	} */

		h3,
		h2 {
			margin: 0;
			font-size: 28px;
			line-height: 40px;
			font-family: 'Poppins Regular';
			margin-bottom: 8px;
			text-transform: none;
			font-weight: 600;
			min-height: 120px;

			span {
				font-size: inherit;
				line-height: inherit;
				font-family: inherit;
				font-weight: 600;
			}

			@media (max-width: 1024px) {
				min-height: auto;
			}
		}

		p {
			margin: 0;
			font-size: 18px;
			line-height: 32px;
			font-family: 'Poppins Regular';

			span {
				font-size: inherit;
				line-height: inherit;
				font-family: inherit;
			}
		}

		.link {
			display: inline-block;
			margin-top: 32px;
			border-radius: 3px;
			padding: 12px 23px;
			font-size: 18px;
			line-height: 27px;
			letter-spacing: 0.43px;
			text-transform: capitalize;
			transition: opacity 0.25s;
			background-color: ${({ theme }) => theme.buttonBg};
			color: ${({ theme }) => theme.buttonColor};
			position: relative;
			z-index: 1;

			&:hover {
				opacity: 0.7;
			}
		}

		.text {
			flex: 1;
		}

		@media (max-width: 1450px) {
			padding: 25px;

			h3,
			h2 {
				font-size: 23px;
				line-height: 34px;
			}

			.link {
				margin-top: 28px;
			}
		}

		@media (pointer: coarse), (max-width: 1024px) {
			padding: 16px;

			h3,
			h2 {
				font-size: 23px;
				line-height: 34px;
			}

			.link,
			p {
				font-size: 14px;
				line-height: 26px;
			}
		}

		@media (max-width: 768px) {
			h3,
			h2 {
				font-size: 21px;
				line-height: 25px;
			}

			.link {
				padding: 9px 13px;
			}
		}

		@media (max-width: 525px) {
			padding: 12px 16px;

			p {
				font-size: 14px;
				line-height: 26px;
			}

			.link {
				padding: 5px 13px;
			}
		}
	}

	.top-info {
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		p {
			flex: inherit !important;
			margin: 0;
			font-size: 13px;
			line-height: 32px;
			font-family: 'Poppins Regular';
		}

		.text {
			flex: auto;

			&:last-of-type {
				opacity: 0.7;
			}
		}

		span {
			width: 4px;
			height: 4px;
			border-radius: 100%;
			background-color: #2a2a2a;
			margin: 0 8px;
		}

		@media (max-width: 1450px) {
			margin-bottom: 12px;

			p {
				display: block !important;
			}
		}

		@media (pointer: coarse), (max-width: 1024px) {
			p {
				font-size: 12px;
			}
		}

		@media (max-width: 525px) {
			margin-bottom: 6px;
		}
	}
`

export const PaginationWrap = styled.nav`
	width: 100%;

	ul {
		display: flex;
		justify-content: flex-end;
		padding: 0 15px;
		margin: 0;

		li {
			button {
				padding: 5px 12px;
				mix-blend-mode: difference;
				color: white;
				font-size: 18px;
				line-height: 27px;
				transition: opacity 0.25s;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;

				&:hover {
					opacity: 0.6;
				}

				&.page-item {
					padding: 0;

					&.left {
						transform: rotate(180deg);
					}
				}
			}

			&.active {
				button {
					font-weight: 600;

					&:hover,
					&:focus {
						opacity: 1;
					}
				}
			}
		}
	}
`

// Single Post

export const SPHero = styled.div`
	position: relative;
	padding-top: 38.4%;
	overflow: hidden;

	.gatsby-image-wrapper,
	img {
		position: absolute !important;
		width: 110%;
		top: 50%;
		transform: translateY(-50%);
	}

	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.8743872549019608) 10%,
			rgba(250, 250, 250, 0) 100%
		);

		z-index: 1;
	}

	@media (max-width: 625px) {
		padding-top: 60%;

		.gatsby-image-wrapper,
		img {
			width: 165%;
		}
	}

	@media (max-width: 525px) {
		padding-top: 80%;

		.gatsby-image-wrapper,
		img {
			width: 230%;
		}

		.overlay {
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.5) 100%,
				rgba(250, 250, 250, 0) 100%
			);
		}
	}
`

export const PContent = styled.article`
	max-width: 140.8em;
	padding: 50px 0;
	margin: 0 auto;
	position: relative;
	display: flex;
	flex-wrap: wrap;

	.article-container {
		flex: 1;

		& > div:last-of-type {
			margin-bottom: 0;
		}
	}

	.singleText,
	.titleText {
		max-width: 660px;
		margin: 0 auto;

		h3,
		h4,
		h5,
		h6 {
			font-size: 32px;
			line-height: 45px;
			margin-bottom: 24px;

			@media (max-width: 1024px) {
				font-size: 21px;
				line-height: 34px;
			}
		}
	}

	.singleText {
		margin-bottom: 100px;

		&:nth-of-type(1) {
			position: relative;

			&::first-letter {
				float: left;
				padding-right: 10px;
				font-size: 60px;
				line-height: 1;
				color: #fff;
				font-size: 90px;
			}
		}

		p {
			margin-bottom: 24px;

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				text-transform: initial;
				color: white;
				text-decoration: underline;
				transition: opacity 0.25s;
				font: inherit;

				&:hover {
					opacity: 0.5;
				}
			}
		}

		&.quote {
			p {
				font-style: italic;
			}

			svg {
				margin-bottom: 16px;

				path {
					fill: white;
					mix-blend-mode: difference;
				}
			}
		}

		ul li {
			list-style: disc;
		}
	}

	.titleText {
		margin-bottom: 100px;

		h2 {
			margin-bottom: 24px;
		}

		p {
			margin-bottom: 24px;

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				text-transform: initial;
				color: white;
				text-decoration: underline;
				transition: opacity 0.25s;
				font: inherit;

				&:hover {
					opacity: 0.5;
				}
			}
		}

		ul {
			li {
				list-style: disc;
			}

			@media (max-width: 525px) {
				padding-left: 25px;
			}
		}
	}

	.singleImage {
		margin: 28px 0 128px 0;
		padding: 0 60px;

		.gatsby-image-wrapper {
			max-height: 710px;
		}
	}

	@media (max-width: 1450px) {
		padding-left: 5em;
		padding-right: 5em;
	}

	@media (pointer: coarse), (max-width: 1024px) {
		.singleText {
			margin-bottom: 48px;

			&.quote svg {
				width: 40px;
				margin-bottom: 0;
			}
		}

		.titleText {
			margin-bottom: 48px;

			h2 {
				font-size: 21px;
				line-height: 34px;
				margin-bottom: 16px;
			}
		}

		.singleImage {
			margin: 0;
			margin-bottom: 48px;
		}
	}

	@media (max-width: 925px) {
		.singleText,
		.titleText {
			max-width: 472px;
		}
	}

	@media (max-width: 700px) {
		flex-direction: column;
	}

	@media (max-width: 625px) {
		padding: 2.4em 1.6em;

		.titleText {
			margin-bottom: 48px;
		}

		.singleImage {
			padding: 0;
		}
	}

	.social-share {
		min-width: 36px;
		margin-top: -50px;
		padding-top: 50px;

		.sticky {
			padding-top: 50px;
		}

		> div {
			width: auto !important;
			display: flex;
			flex-direction: column;
		}

		svg {
			margin-bottom: 12px;
			transition: opacity 0.25s;

			path,
			circle,
			rect {
				transition: fill 0.25s;
			}
		}

		button:hover svg {
			path,
			circle {
				fill: white;
			}
		}

		.twitter-icon:hover svg {
			path.icon {
				fill: #1da1f2;
			}
		}

		.facebook-icon:hover svg {
			path.icon,
			circle.icon {
				fill: #4267b2;
			}
		}

		.linkedin-icon:hover svg {
			path.icon,
			circle.icon {
				fill: #0077b5;
			}
		}

		.email-icon:hover svg {
			path.icon,
			circle.icon,
			path {
				fill: #2a2a2a;
			}

			rect {
				fill: white;
			}
		}

		.reddit-icon:hover svg {
			path.icon,
			circle.icon {
				fill: #ff4500;
			}
		}

		@media (max-width: 1450px) {
			/* padding-left: 5em;
			padding-right: 5em; */
		}

		@media (max-width: 700px) {
			position: relative;
			flex-direction: row;
			top: 0;
			justify-content: center;
			margin-bottom: 48px;

			.sticky {
				padding-top: 0;
			}

			> div {
				flex-direction: row;
				justify-content: center;
				transform: unset !important;
				position: relative !important;
			}

			button {
				margin-right: 12px;
			}

			svg {
				margin: 0;
			}
		}

		@media (max-width: 525px) {
			margin-bottom: 24px;
		}
	}
`

export const InfoWrap = styled.div`
	max-width: 140.8em;
	position: absolute;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	z-index: 2;

	h1 {
		color: white;
		font-size: 42px;
		line-height: 60px;
		font-family: 'Mont Heavy';
		max-width: 700px;
	}

	p {
		color: white;
	}

	.bot-info {
		display: flex;

		p {
			margin-top: 0;
			font-size: 14px;
			font-family: 'Poppins Regular';
		}

		.date {
			text-transform: uppercase;
			margin-right: 30px;
		}
	}

	@media (max-width: 1450px) {
		padding-left: 5em;
		padding-right: 5em;
	}

	@media (pointer: coarse), (max-width: 1024px) {
		h1 {
			font-size: 23px;
			line-height: 34px;
		}
	}

	@media (max-width: 625px) {
		padding-left: 1.6em;
		padding-right: 1.6em;

		h1 {
			font-size: 18px;
			line-height: 27px;
		}

		.bot-info p {
			font-size: 12px;
		}
	}
`

export const AuthorWrap = styled.div`
	max-width: 660px;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;
	margin-bottom: 170px;

	@media (pointer: coarse), (max-width: 1024px) {
		margin-bottom: 96px;

		img {
			width: 13%;
		}

		div > p {
			font-size: 14px;
			line-height: 24px;

			span,
			a {
				font-size: inherit;
			}
		}
	}

	@media (max-width: 925px) {
		max-width: 472px;

		img {
			width: auto;
		}
	}

	@media (max-width: 625px) {
		margin-bottom: 96px;

		img {
			width: 20%;
		}

		span {
			font-size: 12px !important;
		}

		p {
			span {
				font-size: 14px !important;
			}
		}
	}
`

export const AText = styled.div`
	padding-left: 23px;
	p,
	span,
	a {
		font-size: 18px;
		font-family: 'Poppins Regular';
	}

	span {
		color: white;
		mix-blend-mode: difference;
		font-weight: 600;
	}

	a {
		color: white;
		text-decoration: underline;
		text-transform: none;
	}

	@media (max-width: 925px) {
		padding-left: 8px;
	}
`

export const AticleCTA = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 140.8em;
	padding-top: 100px;
	margin: 0 auto;

	.text-wrapp {
		flex: 1;
		z-index: 1;
		mix-blend-mode: difference;
		z .sub {
			font-size: 16px;
			font-family: 'RobotoMono Bold';
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 16px;
			mix-blend-mode: difference;
			color: white;
		}

		h2 {
			font-size: 42px;
			line-height: 60px;
			font-family: 'Mont Heavy';
			max-width: 885px;
			margin-top: 0;
			margin-bottom: 32px;
			mix-blend-mode: difference;
			color: white;
		}

		.link {
			margin: 0;
			background-color: white;
			color: #2a2a2a;
			padding: 9px 18px;
			text-transform: capitalize;
			font-weight: 600;
			border-radius: 3px;
			align-self: flex-start;
			transition: opacity 0.25s;
			z-index: 1;
			display: inline-block;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	.img-wrapp {
		z-index: 1;
		width: 40%;
		max-width: 419px;
		text-align: center;
	}

	@media (max-width: 1450px) {
		padding-left: 5em;
		padding-right: 5em;
	}

	@media (max-width: 1024px) {
		.text-wrapp {
			padding-right: 40px;

			h2 {
				font-size: 21px;
				line-height: 34px;
			}
		}
	}

	@media (max-width: 768px) {
		flex-wrap: wrap;

		.text-wrapp {
			width: 100%;
			padding-right: 0;
			margin-bottom: 50px;
		}

		.img-wrapp {
			width: 100%;
			max-width: 100%;
		}
	}

	@media (max-width: 625px) {
		padding: 7.2em 1.6em 2.4em 1.6em;
		flex-wrap: wrap;

		.text-wrapp {
			margin-bottom: 32px;

			h2 {
				font-size: 21px;
				line-height: 34px;
			}

			.link {
				font-size: 14px;
				padding: 7px 12px;
			}
		}
	}
`

export const Recommended = styled.div`
	max-width: 140.8em;
	padding: 50px 0;
	margin: 0 auto;
	position: relative;

	h3 {
		margin-bottom: 64px;
		font-size: 30px;
	}

	@media (pointer: coarse), (max-width: 1024px) {
		h3 {
			font-size: 21px;
			margin-bottom: 36px;
		}
	}

	@media (max-width: 768px) {
		h3 {
			font-size: 18px;
			margin-bottom: 36px;
		}
	}

	@media (max-width: 1450px) {
		padding-left: 5em;
		padding-right: 5em;
	}

	@media (max-width: 625px) {
		padding-left: 1.6em;
		padding-right: 1.6em;
	}
`
